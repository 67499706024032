import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import CompBBan from "../components/CompBBan";

import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/compB/evidence1-presentation.jpg";
import pic10 from "../assets/images/compB/evidence1-thumb1.jpg";
import pic11 from "../assets/images/compB/evidence2-thumb2.jpg";
import pic12 from "../assets/images/compA/cactus.jpg";
import pic13 from "../assets/images/compA/metadata-agave.jpg";
import pic14 from "../assets/images/compA/dam-prototype.jpg";
import pic15 from "../assets/images/compA/hierarchy-tree.jpg";
import pic16 from "../assets/images/compA/tagging.jpg";

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Competency B</title>
      <meta name="description" content="Competency B" />
    </Helmet>

    <CompBBan />

    <div id="main">
      <section id="intro">
        <div className="inner">
          <header className="major">
            <h2>Introduction</h2>
          </header>
          <p>
            Electronic records consist of any type of digitized information that is recorded and stored on a computer (State of Michigan, n.d.). These records have become increasingly important as computer technology has matured, and include a wide variety of digital information, such as images, databases, documents, and electronic health records (EHRs). Informaticists must regularly evaluate electronic records and ensure that they are properly stored, maintained, updated, and destroyed depending on the record’s type and lifecycle stage. In order to properly manage electronic records, information professionals must develop electronic recordkeeping programs to specify retention, maintenance, and disposal schedules throughout a record’s lifecycle (State of Michigan, n.d.). In many cases, governmental laws define how a record should be stored and how long it should be retained – this is especially true for EHRs, which contain sensitive and private patient health information. Electronic record keeping can also be applied to personal files and can help prevent old and unused files from taking up space on a user’s personal hard drive or cloud storage.
          </p>
        </div>
      </section>
      <section id="overview">
        <div className="inner">
          <header className="major-black">
            <h2>Coursework Overview</h2>
          </header>
          <p>
          Many of the courses in this program have helped me learn about the importance of properly maintaining, managing, and developing electronic records programs. INFM 206, Electronic Records Foundations, introduced me to EHRs, the laws that regulate them, and the various systems that allow EHRs to be accessed digitally. In the United States, federal and state laws strictly define how these records should be stored, and all EHRs systems must abide by these regulations (State of Michigan, n.d.). During this course, I had the opportunity to create a plan to implement an EHR system from the perspective of a project manager. For this project, I focused on adding e-prescribing, patient portals, and Apple Health integrations into an existing EHR system. Additionally, in INFM 206, I had the chance to discuss the challenges and laws surrounding EHR systems with my classmates. Many of my classmates worked in the healthcare field as information professionals, and I learned about the challenges of working with EHRs from a professional perspective. Both the discussion posts in INFM 206 and the course project helped me understand how to evaluate, manage, and develop electronic records programs for healthcare organizations in the United States.
          </p>

          <div className="grid-wrapper">
            <div className="col-6">
              <div className="box">
                <h3>Evidence 1</h3>
                <p>
                  INFM 206 Project: Implementing e-prescribing, patient portals, and Apple Health upgrades into an existing electronic records system for a hospital.
                </p>
                <a href="#two" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="box">
                <h3>Evidence 2</h3>
                <p>
                  INFM 206 Discussion: Maintaining electronic health records (EHRs) within a healthcare organization and using EHRs in the legal field as evidence.
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <img src={pic09} alt="Highlighted images from the project" className="image fit" />
                <p>
                <a href="https://drive.google.com/drive/folders/1sUFQ_KQgUbm-XVJCopfzj8vttD17riP3?usp=sharing" target="_blank" className="button next">
                  View the Project
                </a>
                </p>
              </div>
              <div className="col-6">
                <header className="major">
                  <h3>
                    <i class="icon fa-chart-area"></i> Evidence 1: Planning EHR Integrations for a Hospital
                  </h3>
                </header>
                <p>
                  Implementing an EHR system is very complex, and often requires careful planning and staff training to reap the benefits of the system. Most healthcare organizations in the United States have adopted EHRs to digitally record patient health information, which ultimately has improved health information exchange, patient care, and organizational efficiency (HealthIT.gov, 2019). I had the opportunity to create a plan to implement e-prescribing, patient portals, and Apple Health upgrades into an existing healthcare organization’s EHR system. This plan was created from the perspective of a project manager and was split into three parts: planning, execution, and closure. In a healthcare setting, project managers must have a clear plan and baseline budget before implementing an EHR system.
                </p>
                <p>
                  In order to properly understand how to develop an electronic records program, the first part of my project focused on obtaining sponsorship, stakeholders, planning/scope and requirements gathering. During the development of any project, managers often run into unexpected issues and risks that affect deliverable dates. As I created the second part of this project, I researched common issues that happen during EHR upgrades, and paid close attention to possible risks, delays, and solutions that can arise during developmental procedures. Finally, after a project is implemented, there is a period of transition that takes place before a project can be considered finished. The third phase of my project focused on how project managers for healthcare organizations plan for implementation, training, and transition as the project closes. This project taught me how to evaluate, manage, and develop electronic records programs for EHRs, and how these records can be used for various applications in the healthcare field.
                </p>
              </div>

              <div className="col-6">
                <div className="box">
                  <img src={pic10} alt="A risk assessment slide from the presentation" className="image fit" />
                  <p>
                    <i>Risk Assessment for implementing the EHR integrations</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic11} alt="A project requirements slide from the presentation" className="image fit" />
                  <p>
                    <i>An example of project requirements for an EHR implementation</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-12">
                <header className="major">
                  <h3>Evidence 2: The role of EHRs within a healthcare organization and the legal field</h3>
                </header>
                <p>
                  I do not have experience working in the healthcare field, and before taking INFM 206, I didn’t understand how EHRs worked in the United States. Healthcare in the United States is very complex, and there are many laws that regulate the use of EHRs and protect patient privacy. Although I sometimes felt overwhelmed by the amount of information that I had to learn, many of my classmates worked in the healthcare field, and discussion posts helped me understand what it’s like to work in a hospital as an information professional. One of the most interesting posts that I participated in examined maintaining EHRs within a healthcare organization and explored how EHRs can be used legally as evidence in court.
                </p>
                <p>
                  This post helped me understand that health records are both patient records and legal documents, and how laws like the Health Insurance Portability and Accountability Act (HIPAA) were created to provide protection against healthcare fraud and abuse. I also learned about many different types of ethical issues and challenges health information professionals face in their careers, and how these issues can cause patient information to be exposed. Additionally, my classmates and I discussed how EHRs can be used as key evidence items in civil and criminal cases. Writing this discussion post and learning about my classmates’ real-world experiences helped me understand the importance of protecting patient data and adhering to laws and regulation while developing an electronic records program in a healthcare setting.
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1Z2K9j4NEzj0Co2qWibnrONY5a5c5WHQD/view?usp=sharing" target="_blank" className="button next">
                    Read the Discussion Post
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="conclusion">
        <div className="inner">
          <header className="major">
            <h2>Conclusion</h2>
          </header>
          <p>
            Properly evaluating, managing, and developing electronic records programs is an essential part of creating a strong information system that protects user data. In the healthcare field, EHR systems are difficult to develop because they must follow governmental regulations and protect patient privacy. In INFM 206, I had the opportunity to create a project that focused on integrating e-prescribing, patient portals, and Apple Health upgrades into an existing healthcare organization’s EHR system from the perspective of a project manager. This project helped me understand how EHR systems work within a healthcare organization and how to plan, develop, and transition these integrations into an EHR system that is already in use. Additionally, conversations with my classmates who work as information professionals in the healthcare field have helped me understand how electronic records are managed in real-world settings. Researching how EHRs can be used as both patient records and legal documents has helped me understand the laws that govern EHR data and how important it is to properly develop electronic records programs that protect sensitive patient records. This competency has helped me learn about electronic records from a healthcare perspective, which has improved my understanding about evaluating, managing, and working with electronic records in different organizational settings.
          </p>
          <div className="box">
            <h3>References</h3>
            <p>
              HealthIT.gov. (2019, 16 May). What are the Advatages of Electronic Health Records? Retrieved from HealthIT.gov: https://www.healthit.gov/faq/what-are-advantages-electronic-health-records
            </p>
            <p>
              State of Michigan. (n.d.). Frequently Asked Questions about Electronic Records for Local Governments. Retrieved from State of Michigan Records Management Services: https://www.michigan.gov/documents/hal_mhc_rms_electronic_records_125548_7.pdf
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Landing;
