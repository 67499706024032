import React from 'react'

const CompBBan = (props) => (
    <section id="banner" className="style2 bg-image-compb">
        <div className="inner">
            <header className="major">
                <h1>Competency B</h1>
            </header>
            <div className="content">
                <p>  Evaluate, manage, and develop electronic records programs and
                  applications in a specific organizational setting</p>
            </div>
        </div>
    </section>
)

export default CompBBan
